import { ReactNode, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
// import { isMobile } from 'react-device-detect';

import { GlobalStateProvider } from 'src';
import { AppStore } from 'src/store';
import { AuthStore } from 'src/store/AuthStore';
// import Login from 'src/scenes/Public/Login';

type PublicAuthGuardProps = {
    children: ReactNode;
}

function PublicAuthGuard({ children }: PublicAuthGuardProps) {
    const { authStore } = useContext<AppStore>(GlobalStateProvider);
    // const user = authStore.getUser();
    const { dealId } = useParams<{ dealId: string }>();
    const [dealPublic, setDealPublic] = useState<boolean | undefined>();

    useEffect(() => {
        const updateDealPublic = async () => {
            setDealPublic(await AuthStore.getDealPublic(dealId));
        };

        updateDealPublic();
    }, [authStore, dealId]);

    if (typeof dealPublic === 'undefined') {
        return null;
    }

    // TODO: to make it back one day...
    // if (!isMobile && !user && !dealPublic) {
    //     return <Login />;
    // }

    return children;
}

export default PublicAuthGuard;

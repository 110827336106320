type FormatOptions = {
    style?: 'currency' | 'decimal' | 'percent';
    maximumSignificantDigits?: number;
    currency?: string;
    minimumFractionDigits?: number;
    maximumFractionDigits?: number,
    currencyDisplay?: string;
}

export const formatNumber = (
    number: number | null | undefined,
    formatOptions?: FormatOptions,
    currency?: string,
    fallbackValue: string = '-',
): string => {
    if (typeof number !== 'number' || (!number && number !== 0)) {
        return fallbackValue;
    }

    const formatConfig = {
        style: 'currency',
        currency: currency || 'GBP',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        currencyDisplay: 'symbol',
        ...formatOptions
    };

    return new Intl.NumberFormat('en-GB', formatConfig).format(number);
};

import React, {
    useContext
} from 'react';
import { IconButton, SvgIcon } from '@material-ui/core';
import { GlobalStateProvider } from 'src';
import { AppStore } from 'src/store';
import { useHistory } from 'react-router';
import { useSnackbar } from 'notistack';
import {
    LogOut as LogOutIcon,
} from 'react-feather';

function Logout() {
    const { authStore } = useContext<AppStore>(GlobalStateProvider);
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    const handleLogout = async () => {
        try {
            await authStore.logout();
            history.push('/login');
        } catch (error) {
            enqueueSnackbar('Unable to logout', {
                variant: 'error'
            });
        }
    };

    return (
        <IconButton aria-label="Logout" onClick={handleLogout}>
            <SvgIcon>
                <LogOutIcon stroke="#ffffff" />
            </SvgIcon>
        </IconButton>
    );
}

export default Logout;

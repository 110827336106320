import React, { ReactNode, useContext } from 'react';
import { Redirect } from 'react-router-dom';

import { GlobalStateProvider } from 'src';
import { AppStore } from 'src/store';

type AuthGuardProps = {
    children: ReactNode;
}

function AuthGuard({ children }: AuthGuardProps): ReactNode {
    const { authStore } = useContext<AppStore>(GlobalStateProvider);
    const currentUser = authStore.getUser();

    if (!currentUser?.id || currentUser.dealId) {
        return <Redirect to="/login" />;
    }

    return children;
}

export default AuthGuard;

import React, { ComponentType, ReactElement, useContext, useEffect } from 'react';
import { matchPath, useLocation } from 'react-router';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { Box, Drawer, List, ListSubheader, makeStyles } from '@material-ui/core';

import { GlobalStateProvider } from 'src';
import { AppStore } from 'src/store';
import NavItem from './components/NavItem';
import { navConfigs } from './lib';

export type TNavItem = {
    title?: string;
    icon?: ComponentType;
    href?: string;
    subheader?: string;
    info?: (props: any) => ReactElement;
    items?: TNavItem[];
}

function renderNavItems({
    items,
    pathname,
    depth = 0,
    minimised = false,
}: { items: TNavItem[], pathname: string, depth?: number, minimised?: boolean }) {
    return items.reduce(
        // eslint-disable-next-line no-use-before-define
        (acc: ReactElement[], item: TNavItem) => reduceChildRoutes({ acc, pathname, item, depth, minimised }),
        []
    );
}

function reduceChildRoutes({
    acc,
    pathname,
    item,
    depth = 0,
    minimised,
}: { acc: ReactElement[], pathname: string, item: TNavItem, depth?: number, minimised: boolean }) {
    const key = item.title! + depth;

    if (item.items) {
        const open = matchPath(pathname, {
            path: item.href,
            exact: false
        });

        acc.push(
            <NavItem
                depth={depth}
                icon={item.icon}
                key={key}
                info={item.info}
                open={Boolean(open)}
                title={item.title || ''}
            >
                {renderNavItems({
                    depth: depth + 1,
                    pathname,
                    items: item.items
                })}
            </NavItem>
        );
    } else {
        acc.push(
            <NavItem
                depth={depth}
                href={item.href}
                icon={item.icon}
                key={key}
                info={item.info}
                title={item.title || ''}
                minimised={minimised}
            />
        );
    }
    return acc;
}

const useStyles = makeStyles((theme) => ({
    desktopDrawer: ({ open }: { open: boolean }) => ({
        width: open ? 256 : 48,
        top: 64,
        height: 'calc(100% - 64px)',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: open ? theme.transitions.duration.leavingScreen : theme.transitions.duration.enteringScreen,
        }),
    }),
    avatar: {
        cursor: 'pointer',
        width: 64,
        height: 64
    },
    logo: {
        backgroundColor: theme.palette.primary.main
    }
}));

type TNavBar = {
    open: boolean;
    onClose: () => void;
}

function NavBar({ open, onClose }: TNavBar): ReactElement {
    const classes = useStyles({ open });
    const location = useLocation();
    const { authStore } = useContext<AppStore>(GlobalStateProvider);
    const currentUser = authStore.getUser();
    const navConfig: TNavItem[] = currentUser?.role ? navConfigs[currentUser.role] || [] : [];
    const availabilityDashboardUsers = ['cknk4bby7695930z9xqecks1m', '13', 'ckuwhalvp15628311egrnzbsf3'];
    if (!availabilityDashboardUsers.includes(currentUser.id)) {
        const findIndex = navConfig.map((item) => item.subheader).indexOf('Admin');
        if (findIndex > -1) {
            navConfig.splice(findIndex, 1);
        }
    }

    useEffect(() => {
        if (open && onClose) {
            onClose();
        }
    }, [location.pathname, open, onClose]);

    const content = (
        <Box
            height="100%"
            display="flex"
            flexDirection="column"
        >
            <PerfectScrollbar options={{ suppressScrollX: true }}>
                <Box p={open ? '1rem' : '1rem 0'} minWidth="256px">
                    {open && navConfig.map((config) => (
                        <List
                            key={config.subheader}
                            subheader={<ListSubheader disableGutters disableSticky>{config.subheader}</ListSubheader>}
                        >
                            {renderNavItems({ items: config.items!, pathname: location.pathname })}
                        </List>
                    ))}
                    {!open && navConfig
                        .reduce<TNavItem[]>((acc, { items, ...rest }) => [
                            ...acc,
                            ...(items ? items.map((item) => ({ ...rest, items: [item] })) : []),
                        ], [])
                        .map((config) => (
                            <List key={config.subheader}>
                                {renderNavItems({ items: config.items!, pathname: location.pathname, minimised: true })}
                            </List>
                        ))}
                </Box>
            </PerfectScrollbar>
        </Box>
    );

    return (
        <Drawer
            anchor="left"
            classes={{ paper: classes.desktopDrawer }}
            onClose={onClose}
            variant="persistent"
            open
        >
            {content}
        </Drawer>
    );
}

NavBar.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool,
};

export default NavBar;

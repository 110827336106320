import React from 'react';

type TLogo = {
    variation?: 'white' | 'blue';
    height?: string;
    width?: string;
}

function Logo({ variation = 'white', ...rest }: TLogo) {
    const image = variation === 'blue' ? 'logoBlue' : 'logoWhite';
    const url = `/app/static/${image}.svg`;
    return (
        <img
            alt="Logo"
            src={url}
            {...rest}
        />
    );
}

export default Logo;

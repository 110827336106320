import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-quill/dist/quill.snow.css';
import 'nprogress/nprogress.css';
import React, { createContext } from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import * as serviceWorker from 'src/serviceWorker';
import { AppStore } from 'src/store';
import App from 'src/App';
import { ApolloProvider } from '@apollo/client';
import { client } from 'src/services/api/apollo';
import { createBrowserHistory } from 'history';
import { matchPath } from 'react-router-dom';
import { APP_VERSION, SENTRY_URL } from './config';

const routes = [
    { path: '/admin/broker' },
    { path: '/app' },
    { path: '/app/clients' },
    { path: '/app/clients/:clientId' },
    { path: '/app/deals' },
    { path: '/app/deals/:dealId' },
    { path: '/app/deals/map/:dealId' },
    { path: '/app/offices' },
    { path: '/app/offices' },
    { path: '/app/properties/:propertyId' },
    { path: '/app/properties/:propertyId' },
    { path: '/app/properties/map' },
    { path: '/app/properties/map' },
    { path: '/app/providers' },
    { path: '/app/providers' },
    { path: '/app/providers/:providerId' },
    { path: '/app/providers/:providerId' },
    { path: '/app/reports/dashboard' },
    { path: '/404' },
    { path: '/404' },
    { path: '/admin' },
    { path: '/app' },
    { path: '/app' },
    { path: '/login' },
    { path: '/login' },
    { path: '/providerRegister/:registerId/:status' },
    { path: '/offices/:dealId' },
    { path: '/offices/:dealId/:officeType' },
    { path: '/offices/:dealId/office/:initialPropertyId' },
];

const history = createBrowserHistory();
if (process.env.NODE_ENV !== 'development' && SENTRY_URL !== '') {
    Sentry.init({
        dsn: SENTRY_URL,
        integrations: [
            new Integrations.BrowserTracing({
                // Can also use reactRouterV4Instrumentation
                routingInstrumentation: Sentry.reactRouterV5Instrumentation(history, routes, matchPath),
            })
        ],
        release: APP_VERSION,
        tracesSampleRate: 0.65,
    });
}

const appStore = new AppStore();

export const GlobalStateProvider = createContext(appStore);
ReactDOM.render(
    <GlobalStateProvider.Provider value={appStore}>
        <ApolloProvider client={client}>
            <App history={history} />
        </ApolloProvider>
    </GlobalStateProvider.Provider>,
    document.getElementById('root')
);

serviceWorker.unregister();

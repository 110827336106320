import { useState, Dispatch, SetStateAction, useCallback } from 'react';
import { MapSearch } from 'src/components/MapSearchBar/lib';
import { SelectedDealFragment } from 'src/generated/graphql';

export interface UseDealStore {
    currentDealId: string | undefined;
    setCurrentDealId: Dispatch<SetStateAction<string | undefined>>;
    providersToRegister: string[],
    setProvidersToRegister: (list: string[]) => void;
    officesToRegister: string[],
    setOfficesToRegister: (list: string[]) => void;
    mapSearchSettings?: MapSearch;
    setMapSearchSettings: (settings: MapSearch) => void;
    deal?: SelectedDealFragment;
    setDeal: (deal: SelectedDealFragment) => void;
    selectedProperties: string[];
    setSelectedProperties: Dispatch<SetStateAction<string[]>>;
}

export const useDealStore = (): UseDealStore => {
    const [currentDealId, setCurrentDealId] = useState<string>();
    const [providersToRegister, updateProviders] = useState<string[]>([]);
    const [officesToRegister, updateOffices] = useState<string[]>([]);
    const [mapSearchSettings, setMapSearchSettings] = useState<MapSearch>();
    const [deal, setDeal] = useState<SelectedDealFragment>();
    const [selectedProperties, setSelectedProperties] = useState<string[]>([]);

    const setProvidersToRegister = useCallback((list: string[]) => {
        updateProviders(list);
    }, []);

    const setOfficesToRegister = useCallback((list: string[]) => {
        updateOffices(list);
    }, []);

    return {
        currentDealId,
        setCurrentDealId,
        providersToRegister,
        setProvidersToRegister,
        officesToRegister,
        setOfficesToRegister,
        mapSearchSettings,
        setMapSearchSettings,
        deal,
        setDeal,
        selectedProperties,
        setSelectedProperties
    };
};

type LatLng = {
    lat: number;
    lng: number;
}

// @ts-ignore
const createPoint = (lat: number, lng: number) => new window.google.maps.LatLng(lat, lng);

export const shortenLocation = (location: string, itemsToShow: number = 3): string => {
    const locations = location?.split(',');
    let displayLocation = location;
    if (locations && locations.length > itemsToShow) {
        displayLocation = locations.slice(0, itemsToShow).join(',').concat(', ...');
    }
    return displayLocation;
};

const convertLatLngToCenterOfVisibleSpace = (latlng: LatLng, map: any, google: any, isMobile = false) => {
    const mapBounds = map.getBounds();
    const NE = mapBounds.getNorthEast();
    const SW = mapBounds.getSouthWest();
    const width = NE.lng() - SW.lng();
    const height = NE.lat() - SW.lat();
    // const height = SW.lat() - NE.lat();
    const visibleMult = 0.42;
    const visibleWidth = width * visibleMult;

    const visibleHeight = height * visibleMult;

    const widthOffset = visibleWidth * 0.6;
    const heightOffset = isMobile ? visibleHeight * 0.3 : 0;

    const lat = latlng.lat + heightOffset;
    const lng = latlng.lng - widthOffset;

    return new google.maps.LatLng(lat, lng);
};

export const panToCenterOfVisibleSpace = (latlng: LatLng, map: any, google: any, isMobile = false) => {
    const newLatLng = convertLatLngToCenterOfVisibleSpace(latlng, map, google, isMobile);
    map.panTo(newLatLng, { top: 200 });
};

const setLocation = (results: any[]) => results.filter((item) => item.types.includes('country'))[0].formatted_address;

export const positionMap = (
    map: any,
    points: { lat: number, lng: number }[],
    fullscreen: boolean = true,
    userLatLng?: {lat: number | null, lng: number | null},
    offset?: number
) => {
    if (!map) {
        return;
    }
    // @ts-ignore
    if (points.length > 1 && (window && window.google)) {
        // @ts-ignore
        const bounds = new window.google.maps.LatLngBounds();
        points.forEach((point) => {
            if (point.lat && point.lng) {
                const location = createPoint(point.lat, point.lng);
                bounds.extend(location);
            }
        });
        const options = fullscreen ? { top: 200 } : { left: offset !== undefined ? offset : 720 };
        map.fitBounds(bounds, options);
    } else if (points && points.length === 1) {
        if (points[0].lat && points[0].lng) {
            map.panTo({ lat: points[0].lat, lng: points[0].lng });
        }
        // @ts-ignore
    } else if (window && window.google && userLatLng && userLatLng.lat && userLatLng.lng) {
        // @ts-ignore
        const Geocoder = new window.google.maps.Geocoder();
        Geocoder.geocode(
            { location: { lat: userLatLng.lat, lng: userLatLng.lng } },
            (results: any[] | null, status: string) => {
                if (status === 'OK' && results) {
                    if (setLocation(results) === 'United States') {
                        map.panTo({ lat: 40.730610, lng: -73.935242 });
                    } else {
                        map.panTo({ lat: 51.509865, lng: -0.118092 });
                    }
                } else {
                    // eslint-disable-next-line no-console
                    console.error(`Geocode was not successful for the following reason: ${status}`);
                }
            }
        );
    } else {
        map.panTo({ lat: 51.509865, lng: -0.118092 });
    }
};

export const centerMap = (map: any, lat: number, lng: number, zoom?: number) => {
    const location = createPoint(lat, lng);
    map.setCenter(location);
    if (zoom) {
        map.setZoom(zoom);
    }
};

import { ApolloClient, ApolloLink, Observable, InMemoryCache } from '@apollo/client';
import { GRAPHQL_URL, BACKEND_SERVER, APP_VERSION } from 'src/config';
import { TokenRefreshLink } from 'apollo-link-token-refresh';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { getAccessToken, setAccessToken } from 'src/services/accessToken';
import { createUploadLink } from 'apollo-upload-client';

const cache = new InMemoryCache({});
const requestLink = new ApolloLink((operation, forward) => new Observable((observer) => {
    let handle: any;
    Promise.resolve(operation)
        .then(({ setContext }) => {
            const token = getAccessToken();
            if (token) {
                setContext({
                    headers: {
                        authorization: `bearer ${token}`,
                    }
                });
            }
        })
        .then(() => {
            handle = forward(operation).subscribe({
                next: observer.next.bind(observer),
                error: observer.error.bind(observer),
                complete: observer.complete.bind(observer),
            });
        })
        .catch(observer.error.bind(observer));

    return () => {
        if (handle) handle.unsubscribe();
    };
}));

export const client = new ApolloClient({
    link: ApolloLink.from([
        new TokenRefreshLink({
            accessTokenField: 'accessToken',
            isTokenValidOrUndefined: () => {
                const token = getAccessToken();
                if (!token) {
                    return true;
                }

                try {
                    const { exp } = jwtDecode<JwtPayload>(token);

                    return Date.now() < (exp || 0) * 1000;
                } catch (err) {
                    return false;
                }
            },
            fetchAccessToken: () => fetch(`${BACKEND_SERVER}refresh_token`, {
                method: 'POST',
                credentials: 'include'
            }),
            handleFetch: (accessToken) => {
                setAccessToken(accessToken);
            },
            handleError: (err) => {
                // eslint-disable-next-line no-console
                console.warn('Your refresh token is invalid. Try to relogin', err);
            },
        }),
        requestLink,
        createUploadLink({
            uri: GRAPHQL_URL,
            credentials: 'include',
            headers: {
                'Apollo-Require-Preflight': 'true'
            }
        })
    ]),
    cache,
    name: 'kontorWeb',
    version: APP_VERSION
});

import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { AppBar, IconButton, Toolbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Menu as MenuIcon } from 'react-feather';
import Logo from 'src/components/Logo';

const useStyles = makeStyles((theme) => ({
    root: {
        zIndex: theme.zIndex.drawer + 100,
        boxShadow: 'none',
        backgroundColor: theme.palette.primary.main
    },
    toolbar: {
        minHeight: 64
    },
    menuButton: {
        marginRight: '1rem',
    },
}));

type TSimpleTopBar = {
    className?: string;
    onNavOpen: () => void;
}

function SimpleTopBar({ className, onNavOpen, ...rest }: TSimpleTopBar) {
    const classes = useStyles();

    return (
        <AppBar
            className={clsx(classes.root, className)}
            {...rest}
        >
            <Toolbar className={classes.toolbar}>
                <IconButton className={classes.menuButton} color="inherit" onClick={onNavOpen}>
                    <MenuIcon />
                </IconButton>
                <RouterLink to="/app/reports/dashboard">
                    <Logo variation="white" width="144px" />
                </RouterLink>
            </Toolbar>
        </AppBar>
    );
}

SimpleTopBar.propTypes = {
    className: PropTypes.string,
    onMobileNavOpen: PropTypes.func
};

export default SimpleTopBar;

import React, { useContext } from 'react';
import {
    AppBar,
    Avatar,
    Box,
    Hidden,
    IconButton,
    SvgIcon,
    Toolbar,
    Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { LogOut as LogOutIcon } from 'react-feather';
import { useSnackbar } from 'notistack';
import { useHistory, useLocation } from 'react-router';

import { GlobalStateProvider } from 'src';
import { AppStore } from 'src/store';
import { gravatar } from 'src/libs/utils';
import Logo from 'src/components/Logo';

const useStyles = makeStyles((theme) => ({
    root: {
        zIndex: theme.zIndex.drawer + 100,
        boxShadow: 'none',
        backgroundColor: theme.palette.primary.main
    },
    toolbar: {
        justifyContent: 'space-between',
        minHeight: '4rem',
        padding: '0 1rem',
    },
    avatar: {
        height: '2rem',
        width: '2rem',
        marginRight: '0.5rem',
    },
}));

function Header() {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const { authStore } = useContext<AppStore>(GlobalStateProvider);
    const { enqueueSnackbar } = useSnackbar();
    const user = authStore.getUser();
    const avatarUrl = gravatar(user?.email || '');

    const handleLogout = async () => {
        try {
            await authStore.logout();
            history.replace(location.pathname);
        } catch (error) {
            enqueueSnackbar('Unable to logout', { variant: 'error' });
        }
    };

    return (
        <AppBar className={classes.root}>
            <Toolbar className={classes.toolbar}>
                <Logo width="144px" />
                <Box display="flex" alignItems="center">
                    <Box display="flex" alignItems="center" m="0 1rem">
                        <Avatar alt="User" className={classes.avatar} src={avatarUrl} />
                        <Hidden smDown>
                            <Typography variant="h6" color="inherit">
                                {`${user?.firstName || ''} ${user?.lastName || ''}`}
                            </Typography>
                        </Hidden>
                    </Box>
                    <IconButton title="Log Out" aria-label="Logout" onClick={handleLogout}>
                        <SvgIcon>
                            <LogOutIcon stroke="white" />
                        </SvgIcon>
                    </IconButton>
                </Box>
            </Toolbar>
        </AppBar>
    );
}

export default Header;

import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import SplashScreen from 'src/components/SplashScreen';
import { AppStore } from 'src/store';
import { GlobalStateProvider } from '../index';

function Auth({ children }: { children: any }) {
    const { authStore } = useContext<AppStore>(GlobalStateProvider);

    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        const initAuth = async () => {
            await authStore.handleAuthentication();
            setLoading(false);
        };

        initAuth();
    }, [authStore]);

    if (isLoading) {
        return <SplashScreen />;
    }

    return children;
}

Auth.propTypes = {
    children: PropTypes.any
};

export default Auth;

import { observable } from 'mobx';
import jwtDecode from 'jwt-decode';

import { Broker, Contact, Roles } from 'src/generated/graphql';
import { setAccessToken, getAccessToken } from 'src/services/accessToken';
import { getPublicUser, getUser, logout, getDealPublic } from './services/auth';

export class AuthStore {
    @observable
    private user: Broker | undefined;

    private setUser = (broker: any) => {
        this.user = broker;
    };

    public getUser = (): (Broker | Contact) & { dealId?: string, role?: Roles } => this.user!;

    public handleAuthentication = async () => {
        let accessToken: string | null = getAccessToken();

        if (!accessToken) {
            accessToken = new URLSearchParams(window.location.search).get('token');
        }

        if (!accessToken) {
            this.logout();
            return;
        }

        setAccessToken(accessToken);

        const decoded = jwtDecode(accessToken) as (Broker | Contact) & { dealId?: string };
        let user;

        if (decoded.dealId) {
            user = await getPublicUser();
        } else {
            user = await getUser();
            this.setUser(user);

            // @ts-ignore
            window.pendo.initialize({
                visitor: {
                    id: user.id // Required if user is logged in, default creates anonymous ID
                    // email:        // Recommended if using Pendo Feedback, or NPS Email
                    // full_name:    // Recommended if using Pendo Feedback
                    // role:         // Optional

                    // You can add any additional visitor level key-values here,
                    // as long as it's not one of the above reserved names.
                },
            });
        }

        this.setUser(user);
    };

    public static getDealPublic = async (dealId: string) => getDealPublic(dealId);

    public logout = async () => {
        await logout();
        this.user = undefined;
        setAccessToken('');
    };
}

import moment from 'moment';

export const displayDate = (date: Date | undefined, format: string = 'DD MMMM YYYY') => {
    if (!date) {
        return '';
    }
    return moment(date).format(format) || '';
};

export const isAvailabilityStale = (lastUpdated: Date, daysAgo: number) => {
    const daysDate = moment().subtract(daysAgo, 'days');
    return moment(lastUpdated).isBefore(daysDate);
};

/**
 *
 * If the availability date is before today then display today
 */
export const getRollingDate = (date: Date | undefined) => {
    if (!date) {
        return undefined;
    }
    const now = moment().toDate();
    if (moment(date).isBefore(now)) {
        return now;
    }
    return date;
};

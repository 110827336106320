import React, { ReactNode, useContext } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import { GlobalStateProvider } from 'src';
import { AppStore } from 'src/store';

type GuestGuardProps = {
    children: ReactNode;
}

function GuestGuard({ children }: GuestGuardProps): ReactNode {
    const { authStore } = useContext<AppStore>(GlobalStateProvider);

    if (authStore.getUser()?.dealId) {
        return <Redirect to={`/offices/${authStore.getUser().dealId}`} />;
    }

    if (authStore.getUser()?.id) {
        return <Redirect to="/app" />;
    }

    return children;
}

GuestGuard.propTypes = {
    children: PropTypes.any
};

export default GuestGuard;

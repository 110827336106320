import React, {
    Fragment,
    useEffect,
    useRef,
    useState,
} from 'react';
import {
    Avatar,
    Box,
    Button,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Popover,
    SvgIcon,
    Tooltip,
    Typography,
    makeStyles, Badge, Grid, CardActions, CardContent, Card
} from '@material-ui/core';
import {
    Bell as BellIcon,
    Mail as MailIcon,
} from 'react-feather';
import { NotificationFragment, useNotificationsQuery, useUpdateNotificationMutation } from 'src/generated/graphql';
import parse from 'html-react-parser';
import { displayDate } from 'src/libs/utils/date';

const useStyles = makeStyles((theme) => ({
    popover: {
        width: 460
    },
    icon: {
        backgroundColor: 'transparent',
        color: theme.palette.secondary.contrastText
    },
    iconUnread: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText
    },
    listItems: {
        maxHeight: '60vh',
        padding: 0,
        overflow: 'auto',
    },
    action: {
        justifyContent: 'center'
    }
}));

export const Notifications = () => {
    const classes = useStyles();
    const ref = useRef(null);
    const [isOpen, setOpen] = useState(false);
    const [notifications, setNotifications] = useState<NotificationFragment[]>([]);

    const [updateNotification] = useUpdateNotificationMutation({ ignoreResults: false });

    const { loading, error, data } = useNotificationsQuery({
        fetchPolicy: 'cache-and-network',
        pollInterval: 90000
    });

    useEffect(() => {
        setNotifications(data?.notifications || []);
    }, [data]);

    if (loading) return null;
    if (error) return <p />;
    if (!data) return <p>No data</p>;

    const unreadNotifications = notifications.filter((notification) => !notification.readAt);

    const markAllRead = async () => {
        if (unreadNotifications && unreadNotifications.length > 0) {
            await updateNotification({
                variables: {
                    input: {
                        notificationIds: unreadNotifications.map((notification) => notification.id!)
                    }
                }
            });
        }
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        markAllRead();
        setOpen(false);
    };

    return (
        <Fragment>
            <Tooltip title="Notifications">
                <IconButton
                    color="inherit"
                    ref={ref}
                    onClick={handleOpen}
                >
                    <Badge badgeContent={unreadNotifications.length} color="error">
                        <SvgIcon>
                            <BellIcon />
                        </SvgIcon>
                    </Badge>
                </IconButton>
            </Tooltip>

            <Popover
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                classes={{ paper: classes.popover }}
                anchorEl={ref.current}
                onClose={handleClose}
                open={isOpen}
            >
                <Card title="Notifications">
                    <CardContent className={classes.listItems}>
                        {notifications.length === 0 ? (

                            <Box p={2}>
                                <Typography
                                    variant="h6"
                                    color="textPrimary"
                                >
                                    There are no notifications
                                </Typography>
                            </Box>
                        ) : (
                            <List disablePadding>
                                {notifications.map((notification) => (
                                    <ListItem
                                        divider
                                        key={notification.id}
                                    >
                                        <ListItemAvatar>
                                            <Avatar
                                                className={notification.readAt ? classes.icon : classes.iconUnread}
                                            >
                                                <SvgIcon fontSize="small">
                                                    <MailIcon />
                                                </SvgIcon>
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            disableTypography
                                            primary={(
                                                <Grid container>
                                                    <Typography
                                                        variant="subtitle2"
                                                        color="textPrimary"
                                                    >
                                                        {displayDate(notification.createdAt, 'HH:mm - DD MMMM YYYY')}
                                                    </Typography>
                                                    <Typography
                                                        variant="h5"
                                                        color="textPrimary"
                                                    >
                                                        {notification.subject}
                                                    </Typography>
                                                </Grid>
                                            )}
                                            secondary={(
                                                <Grid container style={{ fontFamily: 'Karla' }}>
                                                    {parse(notification.message || '')}
                                                </Grid>
                                            )}
                                        />
                                    </ListItem>
                                ))}
                            </List>
                        )}
                    </CardContent>
                    {notifications.length > 0 && (
                        <CardActions className={classes.action}>
                            <Button
                                onClick={markAllRead}
                                size="small"
                            >
                                Mark all as read
                            </Button>
                        </CardActions>
                    )}
                </Card>

            </Popover>
        </Fragment>
    );
};

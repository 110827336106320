import { Dispatch, SetStateAction, useState } from 'react';

export interface UsePublicStore {
    favouriteReminderDisplayed: boolean;
    setFavouriteReminderDisplayed: Dispatch<SetStateAction<boolean>>

}

export const usePublicStore = (): UsePublicStore => {
    const [favouriteReminderDisplayed, setFavouriteReminderDisplayed] = useState<boolean>(false);

    return {
        favouriteReminderDisplayed,
        setFavouriteReminderDisplayed,
    };
};

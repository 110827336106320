import { IMAGE_SERVER } from 'src/config';
import { Media } from 'src/generated/graphql';

/**
 * Sizes:
 * logoSize: 32 x 32
 * cardSize: 316 x 175
 * officeSize: 118 x 110
 * defaultSize: 1024 x 576
 */
export enum MediaPrefix {
    logo = 'logo',
    card = 'card',
    officeCard = 'officeCard',
    default = 'default',
}

export const serveMedia = (file: string, prefix?: MediaPrefix): string => {
    if (prefix && prefix !== MediaPrefix.default) {
        const parts = file.split('.');
        const extension = parts.pop();
        const prefixed = parts.join('.').concat(`_${prefix}.${extension}`);
        return `${IMAGE_SERVER}/${prefixed}`;
    }
    if (file.includes('as-image') || file.includes('http')) {
        return file;
    }
    return `${IMAGE_SERVER}/${file}`;
};

export const orderMediaPosition = (mediaArray: Media[]) => mediaArray.sort((a: Media, b: Media) => {
    if ((a.position !== null && a.position !== undefined) && (b.position !== null && b.position !== undefined)) {
        return a.position > b.position ? 1 : -1;
    }
    return 0;
});

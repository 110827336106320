import React, { Component, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import NavBar from 'src/components/NavBar/NavBar';
import SimpleTopBar from './components/SimpleTopbar';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height: '100%',
        overflow: 'hidden',
        width: '100%'
    },
    wrapper: {
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden',
        paddingTop: 64,
        [theme.breakpoints.up('lg')]: {
            paddingLeft: 256
        }
    },
    contentContainer: {
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden',
    },
    content: {
        flex: '1 1 auto',
        height: '100%',
        overflow: 'auto',
        backgroundColor: '#F9FAFF'
    }
}));

type TPublicLayout = {
    children: Component;
}

function PublicLayout({ children }: TPublicLayout) {
    const classes = useStyles();
    const [isNavOpen, setNavOpen] = useState(false);

    const handleToggleNavBar = useCallback(() => setNavOpen((prevIsNavOpen) => !prevIsNavOpen), []);

    return (
        <div className={classes.root}>
            <SimpleTopBar onNavOpen={handleToggleNavBar} />
            <NavBar open={isNavOpen} onClose={handleToggleNavBar} />
            <div className={classes.wrapper}>
                <div className={classes.contentContainer}>
                    <div className={classes.content}>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
}

PublicLayout.propTypes = {
    children: PropTypes.any
};

export default PublicLayout;

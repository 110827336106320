import { ComponentType, ReactElement } from 'react';
import { Roles } from 'src/generated/graphql';
import {
    Globe as GlobeIcon,
    Home as HomeIcon,
    Layout as LayoutIcon,
    List as ListIcon,
    ThumbsUp as ThumbsUpIcon,
    User as UserIcon
} from 'react-feather';

export type TNavItem = {
    title?: string;
    icon?: ComponentType;
    href?: string;
    subheader?: string;
    info?: (props: any) => ReactElement;
    items?: TNavItem[];
}

const propertiesItem = {
    subheader: 'Properties',
    items: [
        {
            title: 'Map View Properties',
            icon: GlobeIcon,
            href: '/app/properties/map',
        },
        {
            title: 'List View Properties',
            icon: ListIcon,
            href: '/app/offices/',
        },
    ],
};

const providersItem = {
    subheader: 'Providers',
    items: [
        {
            title: 'View Providers',
            icon: HomeIcon,
            href: '/app/providers',
        },
    ],
};

const dashboardItem = {
    subheader: 'Dashboard',
    items: [
        {
            title: 'Your Dashboard',
            icon: LayoutIcon,
            href: '/app/reports/dashboard',
        },
    ],
};

const dealItem = {
    subheader: 'Deal',
    items: [
        {
            title: 'All Deals',
            icon: ThumbsUpIcon,
            href: '/app/deals',
        },
    ],
};

const clientsItem = {
    subheader: 'Clients',
    items: [
        {
            title: 'View Clients',
            icon: UserIcon,
            href: '/app/clients',
        },
    ],
};

const adminItem = {
    subheader: 'Admin',
    items: [
        {
            title: 'Brokers',
            icon: UserIcon,
            href: '/admin/broker',
        },
        {
            title: 'Regions',
            icon: GlobeIcon,
            href: '/admin/region',
        },
        {
            title: 'Availability Dashboard',
            icon: LayoutIcon,
            href: '/admin/availability-dashboard'
        }
    ],
};

const scraperItem = {
    subheader: 'Scraper',
    items: [
        {
            title: 'Scraper',
            icon: GlobeIcon,
            href: '/app/scraper',
        }
    ],
};

export const navConfigs: { [key in Roles]?: TNavItem[] } = {
    [Roles.Loader]: [propertiesItem, providersItem],
    [Roles.User]: [dashboardItem, dealItem, propertiesItem, providersItem, clientsItem, scraperItem],
    [Roles.Admin]: [dashboardItem, dealItem, propertiesItem, providersItem, clientsItem, adminItem, scraperItem],
    [Roles.Disposal]: [dashboardItem, propertiesItem, providersItem, scraperItem],
    [Roles.Superadmin]: [dashboardItem, dealItem, propertiesItem, providersItem, clientsItem, adminItem, scraperItem]
};

import React, { useState, useEffect, useMemo } from 'react';
import { Router } from 'react-router-dom';
import { create } from 'jss';
import { hotjar } from 'react-hotjar';
import rtl from 'jss-rtl';
import MomentUtils from '@date-io/moment';
import { SnackbarProvider } from 'notistack';
import {
    createStyles,
    jssPreset,
    makeStyles,
    StylesProvider,
    ThemeProvider,
    useMediaQuery,
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import Auth from 'src/components/Auth';
// import CookiesNotification from 'src/components/CookiesNotification';
// import SettingsNotification from 'src/xcomponents/SettingsNotification';
import GoogleAnalytics from 'src/components/GoogleAnalytics';
import ScrollReset from 'src/components/ScrollReset';
import { createMainTheme, THEME } from 'src/theme';
import Routes from 'src/Routes';
import * as Sentry from '@sentry/react';
import { BACKEND_SERVER, HOTJAR_ID, HOTJAR_VERSION } from './config';
import { setAccessToken } from './services/accessToken';
import { useDealStore } from './store/useDealStore';
import { AppContext } from './store/AppContext';
import { usePublicStore } from './store/usePublicStore';
import { PublicContext } from './store/PublicContext';
import Pendo from './components/Pendo';
import { inIframe } from './libs/utils/inIframe';

// const history = createBrowserHistory();
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const useStyles = makeStyles(() => createStyles({
    '@global': {
        '*': {
            boxSizing: 'border-box',
            margin: 0,
            padding: 0,
        },
        html: {
            '-webkit-font-smoothing': 'antialiased',
            '-moz-osx-font-smoothing': 'grayscale',
            height: '100%',
            width: '100%'
        },
        body: {
            height: '100%',
            width: '100%',
            overflow: 'hidden',
        },
        '#root': {
            height: '100%',
            width: '100%',
        },
        '#CookiebotWidget': {
            display: 'none',
        }
    }
}));

function App({ history }: { history: any }) {
    useStyles();
    const theme = createMainTheme({ theme: THEME.KONTOR });
    const dealStore = useDealStore();
    const publicStore = usePublicStore();
    const loadedFromIframe = inIframe();
    const matchesPrint = useMediaQuery('print');

    const [loading, setLoading] = useState(true);

    useMemo(() => {
        if (process.env.NODE_ENV !== 'development' && HOTJAR_ID !== '' && HOTJAR_VERSION !== '') {
            hotjar.initialize(parseInt(HOTJAR_ID, 10), parseInt(HOTJAR_VERSION, 10));
        }
    }, []);

    useEffect(() => {
        fetch(`${BACKEND_SERVER}refresh_token`, {
            method: 'POST',
            credentials: 'include'
        }).then(async (response) => {
            const data = await response.json();
            if (data.ok && data?.accessToken) {
                setAccessToken(data.accessToken);
            }
            setLoading(false);
        });
    }, []);

    if (loading) {
        return <div />;
    }

    return (
        <AppContext.Provider value={dealStore}>
            <PublicContext.Provider value={publicStore}>
                <ThemeProvider theme={theme}>
                    <StylesProvider jss={jss}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <Router history={history}>
                                <SnackbarProvider maxSnack={3}>
                                    {!(loadedFromIframe && matchesPrint) && <Pendo />}
                                    <Auth>
                                        <ScrollReset />
                                        {/* {!(loadedFromIframe && matchesPrint) && <CookiesNotification />} */}
                                        <Routes />
                                        <GoogleAnalytics />
                                        {/*
                                <ScrollReset />
                                <CookiesNotification />
                                <SettingsNotification />
                             */}
                                    </Auth>
                                </SnackbarProvider>
                            </Router>
                        </MuiPickersUtilsProvider>
                    </StylesProvider>
                </ThemeProvider>
            </PublicContext.Provider>
        </AppContext.Provider>
    );
}

export default Sentry.withProfiler(App);
// export default App;

import packageJson from '../package.json';

export const APP_ENV = process.env.NODE_ENV || process.env.APP_ENV;
// or get from process.env.REACT_APP_{var} to handle PROD and DEV environments
export const APP_VERSION = packageJson.version;

export const API_BASE_URL = '/api';

export const FRONTEND_SERVER = process.env.REACT_APP_FRONTEND_SERVER || 'http://localhost:3000/';
export const BACKEND_SERVER = process.env.REACT_APP_BACKEND_SERVER || 'http://localhost:4000/';
export const GRAPHQL_URL = process.env.REACT_APP_GRAPHQL_URL || `${BACKEND_SERVER}/graphql`;
export const port = 4444;
export const PENDO_KEY = process.env.REACT_APP_PENDO_API_KEY || '';

export const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID || '';
export const GMAPS_API_KEY = process.env.REACT_APP_GMAPS_API_KEY || '';

export const IMAGE_SERVER = `https://${process.env.REACT_APP_IMAGE_SERVER}`;

export const SENTRY_URL = process.env.REACT_APP_SENTRY_URL;

export const HOTJAR_ID = process.env.REACT_APP_HOTJAR_ID || '';
export const HOTJAR_VERSION = process.env.REACT_APP_HOTJAR_VERSION || '';

// How many days pass until and entry is out of date
export const OUT_OF_DATE_DAYS = 28;

export const WHAT_3_WORDS_API_KEY = process.env.REACT_APP_WHAT_3_WORDS_API_KEY || '';

export const CONTENTFUL_ACCESS_TOKEN = process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN;
export const CONTENTFUL_SPACE_ID = process.env.REACT_APP_CONTENTFUL_SPACE_ID;

import { ContactOption } from 'src/components/AddProviderModal/components/AddProviderForm';

export const formatContact = (contact: ContactOption): string => {
    const contactItems: string[] = [];
    if (contact.firstName) {
        contactItems.push(contact.firstName);
    }
    if (contact.lastName) {
        contactItems.push(contact.lastName);
    }
    if (contact.email) {
        contactItems.push(`(${contact.email})`);
    }
    return contactItems.join(' ');
};

import React, { Component, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import NavBar from 'src/components/NavBar/NavBar';
import TopBar from 'src/components/TopBar/TopBar';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height: '100%',
        overflow: 'hidden',
        width: '100%'
    },
    wrapper: ({ isNavOpen }: { isNavOpen: boolean }) => ({
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden',
        paddingTop: 64,
        paddingLeft: isNavOpen ? 256 : 48,
        transition: theme.transitions.create('padding', {
            easing: theme.transitions.easing.sharp,
            duration: isNavOpen ? theme.transitions.duration.leavingScreen : theme.transitions.duration.enteringScreen,
        }),
    }),
    contentContainer: {
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden',
    },
    content: {
        flex: '1 1 auto',
        height: '100%',
        overflow: 'auto',
        backgroundColor: '#F9FAFF'
    }
}));

type TDashboardLayout = {
    children: Component;
}

function DashboardLayout({ children }: TDashboardLayout) {
    const [isNavOpen, setNavOpen] = useState<boolean>(false);
    const classes = useStyles({ isNavOpen });

    const handleToggleNavBar = useCallback(() => setNavOpen((prevIsNavOpen) => !prevIsNavOpen), []);

    return (
        <div className={classes.root}>
            <TopBar onNavOpen={handleToggleNavBar} />
            <NavBar open={isNavOpen} onClose={handleToggleNavBar} />
            <div className={classes.wrapper}>
                <div className={classes.contentContainer}>
                    <div className={classes.content}>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
}

DashboardLayout.propTypes = {
    children: PropTypes.any
};

export default DashboardLayout;

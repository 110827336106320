import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { AppBar, Box, IconButton, Toolbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Menu as MenuIcon } from 'react-feather';
import Logo from 'src/components/Logo';
import Account from './components/Account';
import Logout from './components/Logout';
import { Notifications } from './components/Notifications';
// import Notifications from './components/Notifications';

const useStyles = makeStyles((theme) => ({
    root: {
        zIndex: theme.zIndex.drawer + 100,
        boxShadow: 'none',
        backgroundColor: theme.palette.primary.main
    },
    toolbar: {
        minHeight: 64,
        paddingLeft: 0,
        [theme.breakpoints.down(720)]: {
            maxHeight: 64,
        }
    },
    menuButton: {
        marginRight: '1rem',
    },
}));

type TTopBar = {
    className?: string;
    onNavOpen: () => void;
}

function TopBar({ className, onNavOpen, ...rest }: TTopBar) {
    const classes = useStyles();

    return (
        <AppBar
            className={clsx(classes.root, className)}
            {...rest}
        >
            <Toolbar className={classes.toolbar}>
                <IconButton className={classes.menuButton} color="inherit" onClick={onNavOpen}>
                    <MenuIcon />
                </IconButton>
                <RouterLink to="/app/reports/dashboard">
                    <Logo variation="white" width="144px" />
                </RouterLink>
                <Box
                    ml={2}
                    flexGrow={1}
                />
                <Notifications />
                <Logout />
                {/*
                <Search />
                <Contacts />
                <Settings />
                */}
                <Box ml={2}>
                    <Account />
                </Box>
            </Toolbar>
        </AppBar>
    );
}

TopBar.propTypes = {
    className: PropTypes.string,
    onMobileNavOpen: PropTypes.func
};

export default TopBar;

import React, { useContext } from 'react';
import {
    Avatar,
    Box,
    Hidden,
    Typography,
    makeStyles
} from '@material-ui/core';
import { GlobalStateProvider } from 'src';
import { AppStore } from 'src/store';
import { gravatar } from 'src/libs/utils/gravatar';

const useStyles = makeStyles((theme) => ({
    avatar: {
        height: 32,
        width: 32,
        marginRight: theme.spacing(1)
    },
}));

function Account() {
    const classes = useStyles();
    const { authStore } = useContext<AppStore>(GlobalStateProvider);
    const broker = authStore.getUser();
    const avatarUrl = gravatar(broker?.email || '');

    return (
        <Box
            display="flex"
            alignItems="center"
        >
            <Avatar
                alt="User"
                className={classes.avatar}
                src={avatarUrl}
            />
            <Hidden smDown>
                <Typography
                    variant="h6"
                    color="inherit"
                >
                    {`${broker?.firstName || ''} ${broker?.lastName || ''}`}
                </Typography>
            </Hidden>
        </Box>
    );
}

export default Account;

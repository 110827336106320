import { createContext, useContext } from 'react';
import { UsePublicStore } from './usePublicStore';

const publicDefaults = {
    favouriteReminderDisplayed: false,
    setFavouriteReminderDisplayed: () => { },

};

export const PublicContext = createContext<UsePublicStore>(publicDefaults);

export const usePublicContext = () => useContext(PublicContext);

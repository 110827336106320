import { createContext, useContext } from 'react';
import { UseDealStore } from './useDealStore';

const dealDefaults = {
    currentDealId: undefined,
    setCurrentDealId: () => { },
    providersToRegister: [],
    setProvidersToRegister: () => { },
    officesToRegister: [],
    setOfficesToRegister: () => { },
    setMapSearchSettings: () => { },
    mapSearchSettings: undefined,
    deal: undefined,
    setDeal: () => { },
    selectedProperties: [],
    setSelectedProperties: () => {}
};

export const AppContext = createContext<UseDealStore>(dealDefaults);

export const useAppContext = () => useContext(AppContext);

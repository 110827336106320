import { Mutation, Broker, Contact } from 'src/generated/graphql';
import { getMe, getPublicMe, logout as logoutUser, dealPublic } from 'src/services/api/request';

export const getUser = async (): Promise<Broker> => getMe();

export const getPublicUser = async (): Promise<Contact & { dealId?: string }> => getPublicMe();

export const getDealPublic = async (dealId: string): Promise<boolean> => dealPublic(dealId);

export const logout = async (): Promise<Mutation['logout']> => logoutUser();

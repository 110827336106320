import { Mutation, Broker, Contact } from 'src/generated/graphql';
import { gql } from '@apollo/client';
import { client } from './apollo';

export const getMe = async (): Promise<Broker> => {
    const response = await client.query<{me: Broker}>({
        query: gql`
            query me {
                me {
                    id
                    tenant
                    tenantId
                    externalRef
                    firstName
                    lastName
                    email
                    tokenCount
                    role
                    isActive
                }
            }
        `,
        fetchPolicy: 'network-only',
    });

    return response.data!.me;
};

export const getPublicMe = async (): Promise<Contact & { dealId?: string }> => {
    const response = await client.query<{me: Contact & { dealId?: string }}>({
        query: gql`
            query me {
                me {
                    id
                    email
                    firstName
                    lastName
                    dealId
                }
            }
        `,
        fetchPolicy: 'network-only',
    });

    return response.data!.me;
};

export const dealPublic = async (dealId: string): Promise<boolean> => {
    const response = await client.query<{ dealPublic: boolean }>({
        query: gql`
            query dealPublic($dealId: String!) {
                dealPublic(dealId: $dealId)
            }
        `,
        variables: { dealId },
        fetchPolicy: 'network-only',
    });

    return response.data!.dealPublic;
};

export const logout = async (): Promise<Mutation['logout']> => {
    const response = await client.mutate<Mutation>({
        mutation: gql`
            mutation LOGOUT {
                logout
            }
        `,
    });

    return response.data?.logout;
};

export const invalidateTokens = async (): Promise<Mutation['invalidateTokens']> => {
    const response = await client.mutate<Mutation>({
        mutation: gql`
            mutation LOGOUT {
                invalidateTokens
            }
        `,
    });

    return response.data?.invalidateTokens;
};

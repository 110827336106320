import React, { Component, useContext } from 'react';
import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';

import { GlobalStateProvider } from 'src';
import { AppStore } from 'src/store';
import Header from 'src/scenes/Public/Map/components/Header';

type UseStyles = {
    showHeader: boolean;
    isMobile: boolean;
}

const useStyles = makeStyles({
    root: {
        display: 'flex',
        height: '100%',
        overflow: 'hidden',
        width: '100%'
    },
    content: {
        height: (
            { showHeader, isMobile }: UseStyles
        ) => `calc(${isMobile ? '100%' : '100vh'} - ${showHeader ? 4 : 0}rem)`,
        width: '100%',
        marginTop: ({ showHeader }: UseStyles) => `${showHeader ? 4 : 0}rem`,
        position: 'relative',
    },
});

type TPublicLayout = {
    children: Component;
}

function PublicLayout({ children }: TPublicLayout) {
    const { authStore } = useContext<AppStore>(GlobalStateProvider);
    const user = authStore.getUser();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
    const classes = useStyles({ showHeader: !!user, isMobile });

    return (
        <div className={classes.root}>
            {user && <Header />}
            <div className={classes.content}>{children}</div>
        </div>
    );
}

export default PublicLayout;
